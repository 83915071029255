<template>
	<router-view v-if="show"></router-view>
	<div class="data-loading" v-else><van-loading color="#1989fa" /></div>
	<van-number-keyboard safe-area-inset-bottom />
</template>

<script>
import { Loading, NumberKeyboard } from "vant";
export default {
	name: "App",
	data() {
		return {
			show: false,
		};
	},
	components: {
		[Loading.name]: Loading,
		[NumberKeyboard.name]: NumberKeyboard,
	},
	mounted() {
		this.$http
			.get("/v1/dict/session")
			.then(({ data }) => {
				sessionStorage.setItem("channels", JSON.stringify(data));
				this.show = true;
			})
			.catch((err) => console.error("系统运行时发生异常", err));
	},
};
</script>

<style>
html,
body {
	height: 100%;
	background: #f7f8fa;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}
:root:root {
	--van-font-size-md: 16px;
	--van-tabbar-item-icon-size: 24px;
	--van-tabbar-item-font-size: var(--van-font-size-md);
}

.tips {
	font-size: 14px;
	margin: 15px 5px;
	color: #969799;
	padding-bottom: 45px;
}
.tips ol {
	margin-top: 3px;
}
.data-loading {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
