export default [
	{
		path: "/",
		name: "数据查询服务",
		component: () => import("./components/DataFilter.vue"),
	},
	{
		path: "/authorize",
		name: "AdminLogin",
		component: () => import("./components/AdminLogin.vue"),
		meta: {
			title: "登录系统",
		},
	},
	{
		path: "/admin",
		name: "AdminHome",
		component: () => import("./components/AdminHome.vue"),
		children: [
			{
				path: "/admin/import",
				name: "ImportLesson",
				component: () => import("./components/ImportLesson.vue"),
				meta: {
					title: "导入数据",
				},
			},
			{
				path: "/admin/history",
				name: "HistoryImport",
				component: () => import("./components/HistoryImport.vue"),
				meta: {
					title: "历史导入",
				},
			},
			{
				path: "/admin/mine",
				name: "MineMenu",
				component: () => import("./components/MineMenu.vue"),
				meta: {
					title: "我的",
				},
			},
			{
				path: "/admin/channels",
				name: "channelList",
				component: () => import("./components/channel/ChannelList.vue"),
				meta: {
					title: "场次列表",
				},
			},
			{
				path: "/admin/channels/form",
				name: "channelForm",
				component: () => import("./components/channel/form.vue"),
				meta: {
					title: "场次列表",
				},
			},
			{
				path: "/admin/team",
				name: "TeamLeader",
				component: () => import("./components/team/TeamLeader.vue"),
				meta: {
					title: "群主列表",
				},
			},
			{
				path: "/admin/team/:id/tlm",
				name: "TeamMember",
				component: () => import("./components/team/TeamMember.vue"),
				meta: {
					title: "成员列表",
				},
			},
			{
				path: "/admin/team/:id/tlmf",
				name: "TeamMemberForm",
				component: () => import("./components/team/TeamMemberForm.vue"),
				meta: {
					title: "添加成员",
				},
			},
		],
	},
];
